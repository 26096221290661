import { errorToString, InstallerRegistrationPayload, StatusData } from '@oneethos/shared'
import { toast } from 'react-toastify'
import api from '../api-client'
import ActionLink from './action-link'
import { Link } from './link'
// import { SendEnvelopeLink } from './send-envelope-link'

type ProjectActionsProps = {
  project: StatusData
  installer: InstallerRegistrationPayload
}

export const ProjectActions = ({ project, installer }: ProjectActionsProps) => {
  return (
    <div className="project-actions" style={{ textAlign: 'right' }}>
      {project.installersStatus === 'Awaiting Application' ? <>
        <ActionLink
          label="Re-send referral email"
          action={() => api.post(`/loanapps/${project.id}/resend-referral`)}
          doneLabel="Resent referral email"
        />
        {installer.isSupport && project.projectType === 'residential' ? <Link
          label={`View application`}
          target={'_blank'}
          href={`/apply/${project.lookup}`} />
          : null}
      </> : null}
      {installer.isSupport ? <Link
        label={`Find Prequal Reports`}
        href={`/prequal-reports?loanAppId=${project.id}`}
      /> : null}
      {process.env.REACT_APP_NODE_ENV === 'test' && installer.isSupport ? <>
        <ActionLink
          label="Send Test Notification"
          action={() => api.post(`/loanapps/${project.id}/test-notification`)}
          doneLabel="Test Notification(s) Sent"
        />
        <ActionLink
          label="Test DIT"
          action={() => {
            return api.post(`/loanapps/${project.id}/test-dit`)
              .then(({ url }) => { window.open(url, '_blank') })
              .catch(ex => { toast.error(errorToString(ex)) })
          }}
          doneLabel="Tested DIT"
        />
      </> : null}

      {/* {installer.isSupport 
            ? <SendEnvelopeLink 
            project={project} 
            label="Send Loan Docs" 
            template="loan-docs" 
            /> : null}
        {installer.isSupport 
          ? <SendEnvelopeLink 
          project={project} 
          label="Send Preapproval" 
          template="preapproval" 
          /> : null}
        {installer.isSupport ? <button
          className="btn btn-sm btn-link link-secondary p-0"
          onClick={() => {
            api.get(`/c/loanapps/${project.id}/preapproval-letter`)
              .then(() => { })
              .catch(ex => toast.error(errorToString(ex)))
              .finally(() => {
                // setLoading(false)
              })
          }}
        >
          &nbsp;{`View Preapproval Letter`}
        </button>
          : null} */}
    </div>
  )
}

export default ProjectActions
