import React, { useEffect, useState } from 'react'
import './calculator-page.scss'
import { Spinner } from '@fluentui/react'
import api from '../api-client'
import { Section } from '../components'
import { useAppState, useUtmQuery } from '../hooks'
import {
  errorToString,
  dollars,
  paymentByTerm,
  estimatedClosingCosts,
  EMAIL_REGEX,
  dollarsToNum,
  isServiceableAddress,
  StreetAddress,
  ITenant,
  Incentive
} from '@oneethos/shared'
import { AddressInputs, DollarsInput, NumericInput, TextInput } from '../components/forms'
import RadioToggle from '../components/radio-toggle'
import { FaBuilding, FaHandHoldingHeart, FaHouseChimney } from 'react-icons/fa6'
import { toast } from 'react-toastify'

type PaymentCalculatorProps = {
  formData?: any
  isResidential: boolean
  onChange?: (formData: any) => void
  showCalculation?: boolean
  onShowCalculation?: () => void
  financingTenant?: ITenant
  incentive?: Incentive
  loadingCalculation?: boolean
  interestRate?: number
  loanTermMonths?: number
}

const validateEmail = (str: string) => {
  return str.length && EMAIL_REGEX.test(str)
}

const calcSystemCost = (formData: any): number => {
  return dollarsToNum(formData.solarCost || '0') +
    dollarsToNum(formData.roofCost || '0') +
    dollarsToNum(formData.storageCost || '0') +
    dollarsToNum(formData.otherCost || '0')
}

const PaymentCalculator = ({
  formData,
  isResidential,
  onChange,
  showCalculation,
  onShowCalculation,
  financingTenant,
  incentive,
  loadingCalculation,
  interestRate,
  loanTermMonths
}: PaymentCalculatorProps) => {
  const {
    registration: { installer },
    tenant: { config }
  } = useAppState()

  const { rateEffectiveDate } = config

  const loanTermYears = (loanTermMonths / 12).toFixed(1)
  const [hasTouchedSystemSize, setHasTouchedSystemSize] = useState(false)
  const enableCalculationButton = (
    StreetAddress.isValid(formData.installationAddress) &&
    formData.solarCost &&
    formData.systemSizeKW > 0
  )

  const handleChange = (nextData) => {
    const size = parseFloat(nextData.systemSizeKW) || 0
    const solar = dollarsToNum(nextData.solarCost || '') || 0

    const ppw = size && solar ? dollars(solar / (size * 1000)) : ''
    onChange({
      ...nextData,
      systemCost: calcSystemCost(nextData),
      ppWForPV: ppw
    })
  }

  const { state, country } = (formData.installationAddress || {})
  const { systemCost } = formData
  const fees = estimatedClosingCosts(systemCost, state, formData.projectType)
  const loanPlusClosingCosts = systemCost == 0 ? systemCost : systemCost + fees

  return <Section>
    <div className="section-title">
      <h2 className="margin-bottom-0">Loan Monthly Payment Calculator</h2>
    </div>
    <div className="form-group">
      <label className="my-2 mb-3">Select Project Type</label>
      <RadioToggle
        value={formData.projectType}
        onChange={v => handleChange({ ...formData, projectType: v })}
        options={[
          { label: 'Residential', value: 'residential', Icon: FaHouseChimney },
          { label: 'Commercial', value: 'commercial', Icon: FaBuilding },
          { label: 'Non-Profit', value: 'non-profit', Icon: FaHandHoldingHeart },
        ]}
      />
    </div>

    {!formData.projectType ? null : <>
      {isResidential ? null : <div className="form-group">
        <label>Business or Non-Profit Name</label>
        <TextInput
          value={formData.businessName}
          onChange={val => handleChange({ ...formData, businessName: val })}
        />
      </div>}

      <AddressInputs
        address={formData.installationAddress || {}}
        label="Address where solar panels will be installed"
        onChange={address => handleChange({
          ...formData,
          installationAddress: address,
        })}
      />

      {
        (
          isResidential &&
          StreetAddress.isValid(formData.installationAddress) &&
          !isServiceableAddress(state, country, installer.org)
        ) ? (
          <div className="alert alert-danger">
            <b>Warning:</b> the consumer will be unlikely to complete the application for this
            address as it is outside the supported area. For more information, please contact
            your account representative.
          </div>
        ) : null
      }

      <h4>System Details</h4>
      <div className="system-details">
        <div className="system-prices">
          <div className="form-group">
            <label>Solar Price</label>
            <DollarsInput
              value={formData.solarCost}
              onChange={v => handleChange({
                ...formData,
                solarCost: v
              })}
            />
          </div>
          <div className="form-group">
            <label>Roof Price</label>
            <DollarsInput
              emptyOk
              value={formData.roofCost}
              onChange={v => handleChange({ ...formData, roofCost: v })}
            />
          </div>
          <div className="form-group">
            <label>Storage Price</label>
            <DollarsInput
              emptyOk
              value={formData.storageCost}
              onChange={v => handleChange({
                ...formData,
                storageCost: v
              })}
            />
          </div>
          {/* <div className="form-group">
            <label>Other</label>
            <DollarsInput
              emptyOk
              value={formData.otherCost}
              onChange={v => handleChange({
                ...formData,
                otherCost: v
              })}
            />
          </div> */}
        </div>
        <div className="system-size">
          <div className="form-group">
            <label>System Size (kW)</label>
            <NumericInput
              value={formData.systemSizeKW}
              onBlur={() => setHasTouchedSystemSize(true)}
              onChange={v => handleChange({
                ...formData,
                systemSizeKW: v
              })}
            />
          </div>
          <div className="form-group">
            <label>Price Per Watt</label>
            <TextInput
              disabled
              value={formData.ppWForPV}
            />
          </div>
        </div>
      </div>
      {hasTouchedSystemSize && formData.ppWForPV && dollarsToNum(formData.ppWForPV) > 3.75 ? (
        <div className="alert alert-danger">
          Loan applications with PPW higher than $3.75 are unlikely to be approved.
        </div>
      ) : null}
      {!isResidential && hasTouchedSystemSize && systemCost < 25_000 ? (
        <div className="alert alert-danger">
          Minimum loan amount for commercial projects is $25,000
        </div>
      ) : null}
      <div className="w-form mb-3 total-system-cost">
        <label htmlFor="loan-amount" className="field-label"
          style={{ fontSize: '1.1em' }}
        ><b>Total System Cost</b></label>
        <input type="text"
          className="form-control"
          maxLength={256}
          name="name"
          disabled
          style={{ fontSize: '1.1em' }}
          data-name="Name"
          placeholder=""
          id="loan-amount"
          value={dollars(systemCost)}
        />
      </div>
      {financingTenant ? <>
        <ul className="list-grid">
          <li className="list-item">
            <div className="mt-6 d-flex">
              <div className="form-group">
                <label>Financing Provided By:</label>
                {/* TODO: update cache-busting */}
                <img src={`/api/tenant/logo/${financingTenant._id}`} loading="lazy" id="logo"
                  sizes="(max-width: 479px) 83vw, (max-width: 767px) 72vw, 397.8984375px"
                  alt="financing provider logo"
                  style={{ maxHeight: '50px' }}
                />
              </div>

              {incentive ? <div className="form-group">
                <label>Financial Partner:</label>
                <img src={incentive?.financingPartnerLogo} loading="lazy" id="logo"
                  sizes="(max-width: 479px) 83vw, (max-width: 767px) 72vw, 397.8984375px"
                  alt="local financing partner logo"
                  style={{ maxHeight: '50px' }}
                />
              </div> : null}
            </div>

            {rateEffectiveDate ? <h6 className="heading-7">
              Please note that OUR RATES have changed effective {rateEffectiveDate}.
            </h6> : null}
            <h6 className="heading-7">{loanTermYears} year term<br /></h6>
            <h6 className="heading-7">
              {interestRate}%
              {isResidential ? '(with Auto Pay)' : null}
              <br />
            </h6>

            <h6 className="heading-7">No Dealer Fee<br /></h6>
            <h6 className="heading-7" style={{ fontWeight: 'normal' }}>
              {isResidential ? `${financingTenant.name} offers a discount to clients that opt into, and maintain,
            automatic monthly payments. The bill amount will automatically be withdrawn
            from the clients checking account.` : null}
            </h6><br />
            {showCalculation ? <div className="oe-pmt-summary">
              {incentive ? <div className="mb-3">
                <div className="text-block-4">Estimated Payment with {incentive?.financingPartner} Incentive: </div>
                <div className="text-block-3 fw-bold">
                  {dollars(incentive?.estPriceWithIncentives)}
                </div>
                {incentive?.incentiveDescription
                  ? <div className="text-block-4 fw-normal small">{incentive.incentiveDescription}</div>
                  : null}
              </div> : null}

              <div className="text-block-4">Estimated Payment{incentive ? " before incentive" : null}:</div>
              <div className="text-block-3">
                {paymentByTerm(interestRate, -loanTermMonths, loanPlusClosingCosts)}
              </div>
              {isResidential ? <div>
                <>
                  <div className="text-block-4">w/o Auto Pay:</div>
                  <div className="text-block-3" style={{ color: 'var(--neutral--700)' }}>
                    {paymentByTerm(interestRate + 0.25, -loanTermMonths, loanPlusClosingCosts)}
                  </div>
                </>
              </div> : null}
            </div> : null}
          </li>
          {showCalculation ? <PayoffScenarios
            isResidential={isResidential}
            interestRate={interestRate}
            loanPlusClosingCosts={loanPlusClosingCosts}
          /> : null}
        </ul>
      </> : <div style={{ textAlign: 'center' }}>
        {loadingCalculation ? <Spinner /> : <>
          {enableCalculationButton ? null : <div className="alert alert-info">
            Please provide Address, System Size, and System Cost to calculate payment
          </div>}
          <button
            type="button"
            className="btn btn-primary"
            disabled={!enableCalculationButton}
            onClick={() => onShowCalculation()}
          >Calculate Payment</button>
        </>}
      </div>}
    </>}
  </Section >
}

const PayoffScenarios = ({
  isResidential,
  interestRate,
  loanPlusClosingCosts
}) => {
  const scenarios = isResidential ? [15, 12, 7] : [10, 5]

  return <>
    <li>
      <h3 className="heading mb-0">Loan Payoff Scenarios</h3>
    </li>
    {scenarios.map(years => <li key={years}>
      <h5 className="heading-2 mb-0">Pay off in {years} years</h5>
      <div className="text-block-4">Estimated Payment:</div>
      <div className="text-block-3">{paymentByTerm(interestRate, -(years * 12), loanPlusClosingCosts)}</div>
    </li>)}

    <li>
      <p className="paragraph">
        Fully prepay the loan at any time with <strong>absolutely no prepayment penalty or
          fee</strong>. Any payments made in addition to your contractual monthly payment will
        be applied towards a reduction in the principal balance of your loan.
      </p>
    </li>
  </>
}

const LoanInformation = ({ isResidential }) => {
  const { tenant: { config: { interestRate } } } = useAppState()

  return < Section >
    <div className="section-title">
      <h2 className="margin-bottom-0">Loan Information</h2>
    </div>
    <ul className="list" style={{ fontWeight: 600, fontSize: '1.2em' }}>
      <li>
        Clients will save between 20%-30% on project cost
        as OneEthos does not charge dealer fees.
      </li>
      {isResidential ? <>
        <li>Clients can benefit from a 30% tax credit (if applicable). They can choose to
          apply the credit to towards the loan, bringing the monthly payment down.</li>
        <li>Clients’ monthly payment will never increase.</li>
        <li>30-year loan term.</li>
        <li>
          Clients benefit from a {interestRate}% interest rate
          with auto pay, or {interestRate + 0.25}% without auto pay.
        </li>
      </> : <>
        <li>
          Clients can keep any tax credit. They can choose to apply
          the credit to towards the loan, bringing the monthly payment down.
        </li>
        <li>25-year loan term.</li>
      </>}
    </ul>
  </Section>
}

const SendApplicationLink = ({ formData, isResidential, incentive }: PaymentCalculatorProps) => {
  const [email, setEmail] = useState('')
  const [sending, setSending] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [referralId, setReferralId] = useState('')

  const { tenant: { config } } = useAppState()

  const suffix = isResidential ? '' : 'Commercial'
  const interestRate = config[`interestRate${suffix}`]
  const loanTermMonths = config[`loanTermMonths${suffix}`]

  const utm = useUtmQuery()
  const { systemCost } = formData

  const submitReferral = () => {
    setError('')
    setSuccess('')

    if (!validateEmail(email)) {
      setError('Please enter a valid email address')
      return
    } else if (!systemCost) {
      setError('Please enter a valid loan amount')
      return
    }

    setSending(true)
    api.post('/loanapps', {
      ...utm,
      email,
      projectType: formData.projectType,
      businessName: formData.businessName || '',
      interest: interestRate,
      loanDurationMonths: loanTermMonths,
      systemCost: formData.systemCost,
      incentive,
      installationAddress: formData.installationAddress,
      userAddress: formData.installationAddress,
      solarCost: dollarsToNum(formData.solarCost),
      roofCost: dollarsToNum(formData.roofCost || '$0'),
      storageCost: dollarsToNum(formData.storageCost || '$0'),
      systemSizeKW: parseFloat(formData.systemSizeKW),
      ppWForPV: dollarsToNum(formData.ppWForPV)
    }).then(res => {
      if (res.message) {
        setSuccess(res.message)
      } else {
        setSuccess("Thank you for submitting your request. An email confirmation will be sent to your client shortly.")
      }

      if (res._id) {
        setReferralId(res._id)
      }
    }).catch(ex => {
      setSuccess('')
      setError(errorToString(ex))
    }).finally(() => setSending(false))
  }

  if (success) {
    return <div>
      <div className="alert alert-success">{success}</div>

      {referralId ? <div>
        <h3>Has the consumer already signed a contract?</h3>
        <p>Visit the project page to upload the signed contract and avoid delays</p>
        <div className="buttons">
          <a className="btn btn-primary" href={`/project/${referralId}?v=checklist`}>View Project Page</a>
        </div>
      </div> : null}
    </div>
  }


  return <div>
    <h2 className="margin-bottom-medium">Send Application Link </h2>
    <div className="w-form">
      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get">
        <div className="form-group">
          <label>System Cost: </label>
          <div id="send-amount" className="text-large fw-semibold">
            {systemCost ? dollars(systemCost) : '$0.00'}
          </div>
        </div>
        {systemCost >= 125_000 && isResidential ? <div className="alert alert-info">
          Loans larger than $125,000 will require additional income verification
        </div> : null}
        <div className="form-group">
          <label>Enter client email address</label>
          <input type="email"
            className="form-control"
            maxLength={256}
            name="email"
            data-name="email"
            placeholder="client@email.com"
            id="email"
            autoComplete="off"
            value={email}
            onChange={ev => setEmail(ev.target.value)}
          />
        </div>

        <div className="buttons form-group">
          {sending ? <Spinner /> : <button
            type="button"
            className="btn btn-primary"
            onClick={submitReferral}
          >Send Referral Link</button>}
        </div>
        {error ? <div className="alert alert-danger">{error}</div> : null}
      </form>
    </div>
  </div >
}

export const CalculatorPage = () => {
  const [formData, setFormData] = useState<any>({ systemCost: 0 })
  const [showCalculation, setShowCalculation] = useState<boolean>(false)
  const [financingTenant, setFinancingTenant] = useState(null)
  const [incentive, setIncentive] = useState()
  const [loadingCalculation, setLoadingCalculation] = useState(false)
  const { tenant: { config } } = useAppState()

  const isResidential = !['commercial', 'non-profit'].includes(formData.projectType)
  const suffix = isResidential ? '' : 'Commercial'
  const interestRate = config[`interestRate${suffix}`]
  const loanTermMonths = config[`loanTermMonths${suffix}`]

  return <div className="calculator-page">
    <PaymentCalculator
      interestRate={interestRate}
      loanTermMonths={loanTermMonths}
      formData={formData}
      financingTenant={financingTenant}
      isResidential={isResidential}
      onChange={data => {
        // change in address or system price requires recalculation
        setFinancingTenant(null)
        setShowCalculation(false)
        setIncentive(undefined)
        setFormData(data)
      }}
      showCalculation={showCalculation}
      incentive={incentive}
      loadingCalculation={loadingCalculation}
      onShowCalculation={() => {
        const query = new URLSearchParams({
          ...formData.installationAddress,
          projectType: formData.projectType,
          systemCost: formData.systemCost,
          interestRate,
          loanTermMonths
        })

        setLoadingCalculation(true)
        api.get(`/tenant/for-project?${query.toString()}`).then(res => {
          if (res.tenant) {
            setFinancingTenant(res.tenant)
          }

          if (parseFloat(res.incentive?.estPriceWithIncentives) > 0 && isResidential) {
            setIncentive(res.incentive)
          }

          setShowCalculation(true)
        }).catch(ex => toast.error(errorToString(ex)))
          .finally(() => setLoadingCalculation(false))
      }}
    />
    {showCalculation ? <>
      <LoanInformation isResidential={isResidential} />
      <Section>
        <SendApplicationLink
          formData={formData}
          isResidential={isResidential}
          incentive={incentive}
        />
      </Section>
    </> : null}
  </div>
}

export default CalculatorPage
